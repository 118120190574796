html {
  cursor: 'pointer';
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Press Start 2P', cursive;
  font-size: calc(10px);
  color: #1c1c1c;
  overflow: hidden;

}

.App-body {
  background-color: #ffffff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Press Start 2P', cursive;
  font-size: calc(10px);
  color: #1c1c1c;
  overflow: hidden;

}

.Stereo {
  height: 40vmin;
  pointer-events: none;
  display: flex;

  object-fit: contain;
}

.TrackTitle {
  padding: 10px;
  padding-bottom: 50px;
}

.Logo-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.Internal {
  height: 30px;
  padding: 20px;
}

.NinetyNine {
  height: 30px;
  padding: 20px;
}

.Friends {
  width: 40px;
  padding: 20px;
}

.PlayButton {
  height: 50px;
  padding: 10px;
  cursor: 'pointer';
}

.Start {
  padding-bottom: 30px;
  cursor: 'pointer';
}

.RadioName {
  padding-left: 10px;
  padding-right: 10px;
}

.Time {
  justify-content: center;
  padding-bottom: 30px;
}

.MenuBar {
  color: "black";
  padding: 25px;
  display: inline-block;
  top: 0;
  position: fixed;
  align-content: center;
  cursor: pointer;
}

.Spacer {
    width: 100%;
    height: 95px;
}

.Home-Button {
  padding: 10px;
  cursor: 'pointer';
}

.Main-Button {
  padding: 10px;
  cursor: 'pointer';
}

.Schedule-Button {
  padding: 10px;
  cursor: 'pointer';
}

.Twitter-Button {
  padding: 10px;
  cursor: 'pointer';
}

.Logo-row {
  bottom: 0;
  position: fixed;
}

.NinetyNine {
  width: 100px;
  object-fit: contain;
}

.Schedule-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vmin;
}

.Schedule-title {
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;
  padding-right: 20px;
}

.Schedule-row-stack {
  text-align: right;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 20px;
}

a:link {
 color: black;
 text-decoration: "none";
 cursor: 'pointer';
}

a:visited {
 color: black;
 text-decoration: "none";
 cursor: 'pointer';

}

a:hover {
 color: black;
 text-decoration: "none";
 cursor: 'pointer';
}

a:active {
 color: black;
 text-decoration: "none";
 cursor: 'pointer';
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h3 {
  display: block;
  margin: 0;
}

footer {
  display: flex;
  justify-content: center;
}

p {
  font-weight:bold;
}

button {
  background:none;
  border:none;
  margin:0;
  padding:0;
  cursor: pointer;
  font-family: 'Press Start 2P', cursive;
  font-size: calc(10px);
}
